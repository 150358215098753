body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  // background-color: rgb(244, 245, 247) !important;
}

html,
body {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-dots {
  position: absolute;
  bottom: 100px !important;
  display: block;
  width: 100%;
  padding: 10;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li.slick-active button:before {
  font-size: 15px;
  color: white !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  font-size: 15px !important;
  color: white !important;
  opacity: 0.6 !important;
}

@font-face {
  font-family: "alphakind";
  src: local("alphakind"),
    url(./fonts/alphakind/alphakind-webfont.woff) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dosis";
  src: local("dosis"),
    url(./fonts/dosis/dosis-medium-webfont.woff2) format("truetype");
  font-weight: normal;
  font-style: normal;
}
